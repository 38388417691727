<template>
  <div>
    <b-row>
      <b-col
        cols="12"
        md="6"
        class="mb-2"
      >
        <div
          class="sticky-top"
          style="top: 108px"
        >
          <div>
            <h2>Как начать сотрудничать с нами?</h2>
            <p class=" font-weight-normal">
              Приём заявок от родителей начинается с <b class="text-primary">1 июля</b>. <br>
              Заявки будут приниматься на подготовку к школе, по остальным предметам приём заявок начнется с августа. <br>
              Вы можете бесплатно использовать платформу со своими учениками.
            </p>
          </div>

          <instruction-steps-list />
        </div>
      </b-col>

      <b-col
        cols="12"
        md="6"
      >

        <popular-questions />

        <!-- EasyWeek Appointment Widget: https://easyweek.ru/ -->
        <b-card
          id="interview"
          no-body
          class="overflow-hidden"
        >
          <div class="p-2">
            <b-card-title>Собеседование и верификация</b-card-title>
            <b-card-sub-title>Перед тем как принимать заявки, Вам необходимо пройти небольшое собеседование и верификацию.</b-card-sub-title>
          </div>
          <iframe
            src="https://widget.easyweek.io/umikum"
            style="border: 0; max-width: 100%;"
            frameborder="0"
            width="100%"
            height="850"
          />
        </b-card>

        <b-card
          id="video"
          no-body
          class="overflow-hidden"
        >
          <div class="p-2">
            <b-card-title class="d-flex justify-content-between align-center">
              <span class="mt-50">Обучающие видео</span>
              <b-button
                size="sm"
                variant="flat-primary"
                href="https://www.youtube.com/channel/UCnTrt57m0rrPKaTlVaL3Frw"
                class="font-small-3"
                target="_blank"
              >
                <feather-icon
                  icon="ExternalLinkIcon"
                  class="mr-50"
                />
                Все видео
              </b-button>
            </b-card-title>
            <b-card-sub-title>Посмотрите обучающие видео, в которых показаны основные принципы работы с платформой.</b-card-sub-title>
          </div>
          <b-aspect aspect="16:9">
            <youtube
              ref="video"
              video-id="C_Y2oDU1Szo"
              width="100%"
              height="100%"
            />
          </b-aspect>
        </b-card>

        <b-card id="profile">
          <b-card-title>Заполните свой профиль</b-card-title>
          <b-card-sub-title>Вам необходимо загрузить свою фотографию, заполнить все поля в разделе “Мой профиль”, указать данные о своем образовании, стоимости уроков</b-card-sub-title>

          <div class="d-flex justify-end mt-2">
            <b-button
              :to="{name: 'cabinet.profile'}"
              variant="outline-primary"
            >
              Перейти в профиль
            </b-button>
          </div>
        </b-card>

        <instruction-bot />

      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow, BCol, BCard, BCardTitle, BCardSubTitle, BAspect, BButton,
} from 'bootstrap-vue'
import PopularQuestions from '../components/instruction/PopularQuestions.vue'
import InstructionStepsList from '../components/instruction/InstructionStepsList.vue'
import InstructionBot from '../components/instruction/InstructionBot.vue'

export default {
  name: 'Instruction',
  components: {
    InstructionBot,
    InstructionStepsList,
    PopularQuestions,
    BRow,
    BCol,
    BCard,
    BCardTitle,
    BCardSubTitle,
    BAspect,
    BButton,
  },
}
</script>

<style scoped>

</style>
