<template>
  <b-card
    no-body
    class="mb-0"
  >
    <b-card-title class="mb-0 ml-2 mt-2 font-medium-1">
      Выполните несколько простых шагов для начала сотрудничества.
    </b-card-title>
    <b-list-group
      flush
      class="py-50"
    >
      <b-list-group-item
        v-for="(step, key) in steps"
        :key="key"
        v-scroll-to="step.href"
        class="px-2"
        :href="step.href"
      >
        <span class="text-muted mr-1 font-weight-bold">{{ key + 1 }}.</span>
        <span>{{ step.text }}</span>
      </b-list-group-item>
    </b-list-group>
  </b-card>
</template>

<script>
import {
  BCard, BListGroup, BListGroupItem, BCardTitle,
} from 'bootstrap-vue'

export default {
  name: 'InstructionStepsList',
  components: {
    BCard, BListGroup, BListGroupItem, BCardTitle,
  },
  computed: {
    steps() {
      return [
        {
          href: '#faq',
          text: 'Ознакомьтесь с ответами на популярные вопросы',
        },
        {
          href: '#interview',
          text: 'Запишитесь на собеседование',
        },
        {
          href: '#video',
          text: 'Посмотрите обучающее видео',
        },
        {
          href: '#profile',
          text: 'Заполните свой профиль',
        },
        {
          href: '#bot',
          text: 'Подключитесь к Телеграм боту для приема заявок',
        },
        {
          href: '',
          text: 'Пройдите собеседование и верификацию',
        },
        {
          href: '',
          text: 'Получайте заявки',
        },
      ]
    },
  },
}
</script>

<style scoped>

</style>
