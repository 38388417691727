<template>
  <b-card
    id="faq"
    no-body
  >
    <div class="p-2">
      <b-card-title>Популярные вопросы</b-card-title>
      <b-card-sub-title>Ознакомьтесь с ответами на популярные вопросы.</b-card-sub-title>
    </div>
    <app-collapse accordion>
      <app-collapse-item
        v-for="({q, a}, key) in questions"
        :key="key"
        :title="q"
        class="px-50"
      >
        {{ a }}
      </app-collapse-item>
    </app-collapse>
  </b-card>
</template>

<script>
import {
  BCard, BCardTitle, BCardSubTitle,
} from 'bootstrap-vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'

export default {
  name: 'PopularQuestions',
  components: {
    AppCollapseItem,
    AppCollapse,
    BCard,
    BCardTitle,
    BCardSubTitle,
  },
  data: () => ({
    tab: 0,
  }),
  computed: {
    questions() {
      return [
        {
          q: 'Почему преподавателю выгодно с нами?',
          a: 'Сотрудничая с нами Вам не потребуется самостоятельно искать учеников. Вы напрямую договариваетесь с родителями и принимаете оплату от них. Получаете заявки и сами общаетесь с клиентами в телеграм боте. Вы оплачиваете комиссию только за проведенные уроки. А также можете заниматься бесплатно со своими учениками.',
        },
        {
          q: 'Как вы оформляете преподавателей?',
          a: 'Преподавателей мы никак не оформляем, мы предоставляем возможности нашей платформы и находим Вам ученика.',
        },
        {
          q: 'Сколько я могу зарабатывать и какая стоимость уроков?',
          a: 'Стоимость уроков Вы определяете сами на каждого ученика отдельно.\n'
              + 'Вы оставляете свои предложения в соответствие с критериями заявки, количество сделок с клиентами уже зависит от Вас (от опыта и Вашей стоимости урока, заполненности анкеты и тп)',
        },
        {
          q: 'Каким образом происходит оплата клиентами и как списывается комиссия за уроки?',
          a: 'Вы напрямую договариваетесь с родителями (клиентами) и принимаете оплату от них любым удобным Вам способом. Получаете заявки от нас бесплатно и сами общаетесь с клиентами в телеграм боте.\n'
              + 'Нам Вы оплачиваете комиссию в размере до 20% с каждого проведенного урока предварительно пополнив баланс Вашего кошелька на платформе. Списание комиссии происходит только после проведения урока.',
        },
        {
          q: 'Где я смогу увидеть информацию о проведенных уроках?',
          a: 'Вся система абсолютно прозрачна: в личном кабинете вы будете видеть размер оплаты за один урок, комиссию за урок с каждым учеником, а также всю историю начислений и выплат за проведенные уроки. В любой момент вы можете уточнить детали в службе поддержки преподавателей.',
        },
        {
          q: 'Как начать сотрудничать с нами?',
          a: 'После регистрации на платформе напишите нам в чате в личном кабинете и пройдите небольшое собеседование, которое состоит из нескольких вопросов и продлится 10-15 минут. На собеседование надо будет показать свои документы подтверждающие личность и наличие у Вас проф. подготовки. А дальше остается ознакомиться с платформой (много времени это не отнимет),'
              + ' просмотреть обучающие видео, которые помогут сориентироваться в функционале и подскажут, как действовать в любой непонятной ситуации.',
        },
        {
          q: 'Какая продолжительность уроков?',
          a: 'Вы сами решаете какая будет продолжительность уроков, обсуждаете детали с родителями во время приема заявки в телеграм боте.\n'
              + 'В основном все уроки длительностью до 50-60 минут. На занятия с детьми младше 9 лет может отводиться 25 минут.',
        },
        {
          q: 'Могу ли я использовать свои материалы?',
          a: 'Да, конечно. Вы сами можете создавать карточки к урокам и упражнения, на платформе есть все возможности для создания интересных и эффективных учебных материалов.\n'
              + 'Также на платформе достаточно много материалов, созданных сообществом преподавателей и выложенных в публичный доступ, подходящих для уроков с учениками самых разных уровней, возрастов и интересов.',
        },
        {
          q: 'Есть ли какие-то требования к оборудованию?',
          a: 'Чтобы вам было комфортно преподавать на нашей платформе, мы рекомендуем использовать персональные компьютеры или ноутбуки с операционными системами Windows 7 и старше, Mac OS от El Capitan и новее. На этапе отбора мы поможем проверить техническую оснащенность оборудования. Также необходим стабильный интернет. Скорость интернет-соединения: от 5 Мб/с приема и передачи. Замерить скорость соединения можно на SpeedTest или Яндекс.Интернетометре. Смартфоны или планшеты для проведения полноценных занятий, к сожалению, не подойдут.',
        },
        {
          q: 'А если возникнут технические сложности во время уроков?',
          a: 'Вы можете прямо во время урока связаться с нашей службой технической поддержки и они оперативно помогут решить проблему',
        },
        {
          q: 'Можно ли использовать платформу со своими учениками?',
          a: 'Да, конечно. Вы можете бесплатно пользоваться платформой или использовать расширенный платный функционал со своими учениками.\n'
              + 'Более подробно можно узнать на rupetitor.ru',
        },
      ]
    },
  },
}
</script>

<style scoped>

</style>
