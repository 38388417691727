<template>
  <b-card id="bot">
    <b-card-title>Авторизация в боте</b-card-title>
    <b-card-sub-title>
      Вам необходимо будет запустить Телеграм бот, в котором Вы будете принимать заявки, оставлять свои предложения и договариваться с родителями.
    </b-card-sub-title>
    <div class="d-flex align-items-center mt-2">
      <b-avatar
        :src="require('@/assets/images/avatars/default_avatar.png')"
        size="54px"
      />
      <div class="ml-1">
        <h4 class="mb-0">
          Рупетитор заявки
        </h4>
        <a
          href="https://t.me/rupetitor_bot"
          class="card-text"
          target="_blank"
        >
          @rupetitor_bot
        </a>
      </div>
      <div class="flex-fill" />
    </div>
    <div class="thin-divider my-1" />
    <b-row>
      <b-col>
        <p class="font-weight-bold font-medium-2 mb-50 mt-50">
          Перейдите в бота и запустите его
        </p>
        <p>
          Для прохождения идентификации, вам будет предложено отправить боту свой контакт
        </p>
        <b-alert
          variant="warning"
          show
          class="m-0"
        >
          <div class="alert-body">
            <feather-icon
              icon="InfoIcon"
              class="mr-50"
            />
            Telegram-аккаунт должен быть привязан к тому же номеру телефона, с которого вы зарегистрировались
          </div>
        </b-alert>
      </b-col>
      <b-col
        cols="auto"
        class="d-none d-md-inline-block"
      >
        <img
          style="width: 165px"
          :src="require('@/assets/images/bot_qr.png')"
          alt=""
        >
      </b-col>
    </b-row>
    <b-button
      class="d-md-none mt-2"
      block
      variant="primary"
      href="https://t.me/rupetitor_bot"
    >
      Перейти
    </b-button>
  </b-card>
</template>

<script>
import {
  BCard, BCardTitle, BCardSubTitle, BRow, BCol, BAlert, BAvatar, BButton,
} from 'bootstrap-vue'

export default {
  name: 'InstructionBot',
  components: {
    BCard, BCardTitle, BCardSubTitle, BRow, BCol, BAlert, BAvatar, BButton,
  },
}
</script>
